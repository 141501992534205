.navbar {
    background-color: rgb(152 152 152 / 50%);
    backdrop-filter: blur(10px);
    border-radius: 5px;
    position: fixed;
    z-index: 10000;
    margin: 20px;
    height: 120px;
}

.nav-link-custom.active {
    color:  rgb(58 51 51);
}

.navbar-custom-container {
    padding: 20px;
    min-width: 97vw; 
}

.navbar-custom-container > * {
    margin: 0;
}

.navbar-light .navbar-nav .nav-link{
    color: white;
}
  
.nav-link-custom {
    font-size: 2.7rem;
    font-family: 'Cedora-Bold';
}

.nav-buttons{
    gap: 18px;
    width: 100%;
    justify-content: flex-end;
}

.hamburger-btn {
    display: none;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 50px; 
    height: 50px;
    background-color: none;
    border-radius: 10px;
    cursor: pointer;
    padding: 10px; 
    box-sizing: border-box;
  }
  
.hamburger-btn span {
    display: block;
    width: 35px; 
    height: 3px; 
    background-color: #914c5d; 
    margin: 3px 0;
    transition: all 0.3s ease;
    border-radius: 5px;
}

.hamburger-btn span:nth-of-type(2) {
    width: 70%;
    align-self: flex-end;
}
  
.hamburger-btn.active span:nth-of-type(1) {
    transform: translateY(9px) rotate(55deg);
}
  
.hamburger-btn.active span:nth-of-type(2) {
    opacity: 0%;
}
  
.hamburger-btn.active span:nth-of-type(3) {
    transform: translateY(-9px) rotate(-55deg);
}



@media screen and (max-width: 1370px){
    .nav-link-custom {
        font-size: 2.35rem;
    }
}

@media screen and (min-width: 1200px){
    
    .hamburger-btn {
        display: none;
    }
}

@media (min-width: 1101px) {
    .navbar-collapse {
        display: flex !important; 
    }
    .navbar-toggle {
        display: none !important; 
    }
}
  

@media (max-width: 1100px) {

    .navbar {
        background-color: transparent;
        backdrop-filter: none;
    }

    .navbar-custom-container {
        display: flex;
        justify-content: space-between; 
        align-items: center;
        width: 100%;
        padding-right: 1rem; 
    }

    .navbar-light .navbar-nav .nav-link:focus, .navbar-light .navbar-nav .nav-link:hover{
        color: #531626 !important;
    }
    
    .navbar .navbar-toggler {
        display: none; 
    }

    .navbar>.container{
        margin: 0;
    }

    .logo-hided{
        display: none;
    }

    .hamburger-btn {
        display: flex !important;
        position: fixed;
        top: 11%;
        right: 2.5%;
        z-index: 1050;
        transition: transform 0.3s ease-in-out;
        background-color: rgb(152 152 152 / 50%);
        backdrop-filter: blur(10px);
    }

    .hamburger-btn span {
        background-color: #fdfdfd;
    } 

    .navbar-expand-lg .navbar-nav{
        margin-top: 120px;
        flex-direction: column;
    }

    .navbar-collapse-custom {
        display: none;
        flex-direction: column;
        position: fixed;
        right: 0;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: #9d5f6e;
        z-index: 1000;
        transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
        opacity: 0;
        transform: translateX(100%);
    }
    
    .navbar-collapse-custom.show {
        display: flex;
        opacity: 1;
        transform: translateX(0);
    }

    .hamburger-btn.active{
        transform: translateX(0%) translateY(10%);
    }


    .navbar-light .navbar-nav .nav-link{
        color: #fdfdfd !important;
        font-family: 'Cedora-Bold';
        text-align: center !important;
        width: 100vw;
        line-height: 2rem;
    }

}

@media only screen and (max-width: 767.98px) {
    .hamburger-btn{
        top: 3%;
    }
}