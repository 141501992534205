
.projects-view .images-section {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
}

.projects-view .project-card{
    height: auto;
    display: flex;
    flex-direction: column;
    color: #6E132A;
    min-height: 440px;
    justify-content: flex-start;
    position: relative;
    max-width: 100%;
}


.projects-view .project-card-container {
    padding: 10px;
    position: relative;
    overflow: hidden;
    transition: transform 0.3s ease-in-out;
    border: none;
}

.projects-view .title-projects {
    font-size: 3.62rem;
    color: #6E132A;
    margin: 15px 0;
}

.projects-view .card-title {
    font-size: 1.5rem;
    color: #6E132A;
}

.projects-view .btn{
    font-size: 4.5rem;
}

/* Efecto al pasar el cursor sobre el contenedor */
.projects-view .project-card-container:hover {
  transform: scale(1.05);
}

.projects-view .project-card-container:hover .project-card-container {
  opacity: 0.8;
}

.projects-view .project-card-container:hover .start-button-projects {
  opacity: 0.8;
}

.projects-view .bottom-section{
    padding: 0;
    margin: 0;
    width: 100vw;
}

.projects-view .left-button-bottom, .projects-view .right-button-bottom{
    display: none;
}

.project-card .card-img{
    aspect-ratio: 1;
}

@media (width >= 1500px){
    .main-image-projects {
        max-height: 900px;
    }
}

@media screen and (min-width: 1200px){

    .main-image-projects {
        max-height: 700px;
    }
    
    .hamburger-btn {
        display: none;
    }
}

@media screen and (max-width: 750px){
    .main-image-projects {
        height: 100vw;
    }

    .projects-view .project-card-container img {
        width: 100%;

    }
    .projects-view .card-title{
        font-size: 1.5rem;
    }

    .main-image-projects {
        width: 100%;
        height: 55vw;
    }

    .projects-view .title-projects{
        text-align: center;
    }    

    .projects-view .project-card{
        justify-content: flex-start;
        aspect-ratio: 8 / 9;
        margin: 0 auto;
    }

}
