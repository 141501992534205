.service-card .card-asesoria {
    display: flex;
    flex-direction: row;
    border-radius: 20px;
    min-height: 216px;
    max-width: 93.5%;
    margin-bottom: 1.7rem;
    margin-left: 10%;
    transition: transform 0.3s ease; /* Esto asegura una transición suave */
    transform: translateY(0)
}

.service-card .card-asesoria:hover {
    transform: none;
    box-shadow: none;
}


.service-card .card-body{
    align-self: center;
    margin-left: 15%;
    margin-top: -5%;
}

.service-card .button-body{
    background-color: #6E132A;
    color: white;
    border: white 2px solid;
    border-radius: 50%;
    padding: 1rem;
    margin: .5rem;
    cursor: pointer;
    position: relative;
    width: auto;
    max-height: 55px;
    opacity: .8;
    top: 0%;
}

.service-card .button-body:hover{
    opacity: 1;
}

.title{
    font-size: 3rem;
    color: #6E132A;
    position: relative;
    top: 1rem;
    left: 5rem;
    margin-top: 2rem;
    margin-bottom: 2rem;
    align-self: auto;
}