.button-col{
    display: flex;
    justify-content: center;
    align-items: center;
}

.left-button-bottom, .right-button-bottom {
    position: relative;
    top: 20%;
    border: none;
    cursor: pointer;
    display: flex; /* Añadido para centrar la flecha */
    justify-content: center; /* Añadido para centrar la flecha */
    align-items: center; /* Añadido para centrar la flecha */
    width: 73px;
    height: 117px;
    background-color: white;
    background-size: contain;
    background-repeat: no-repeat;
    opacity: .8;
}

.left-button-bottom{
    background-image: url(/public/img/UTILS/left-indicator.webp);
}

.right-button-bottom{
    background-image: url(/public/img/UTILS/right-indicator.webp);
}


.left-button-bottom::before, .right-button-bottom::before {
    display: block;
    width: 0;
    height: 0;
}

.btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled):active, .show>.btn-primary.dropdown-toggle{
    background-color: white;
    border: none;
    opacity: 1;
}

.btn-primary.focus, .btn-primary:focus{
    background-color: white;
    border: none;
    box-shadow: none;
    opacity: 1;
    outline: 2px solid white;

}

.left-button-bottom:hover, .right-button-bottom:hover{ 
    background-color: white!important;
    opacity: 1;
}

.left-button-bottom:focus-visible, .right-button-bottom:focus-visible{
    background-color: white!important;
}

.btn:focus-visible{
    border-color: #6E132A;
    box-shadow: none;
}
button:focus-visible {
    outline: 2px solid #6E132A !important;
}

@media only screen and (max-width: 1200px){

    .left-button-bottom, .right-button-bottom{
        top: 30%;
    }

    .start-button-bottom{
        width: 250px;
        height: 100px;
        font-size: 1.7rem !important;
    }

}