@media (min-width: 576px) {
    .modal-dialog {
        max-width: 700px;
        margin-right: auto;
        margin-left: auto;
    }
}

.edit-project-modal .modal-dialog{
    top: 10%;
}

.modal-open .modal{
    backdrop-filter: blur(5px);
}

.modal-header{
    background-color: #fdfdfd;
    border-bottom: #6E132A 1px dotted;
}

.modal-header .btn-close{
    right: 5%;
    top: 2.8%;
}

.modal-title{
    font-family: 'Cedora-Bold' !important;
    color: #6E132A;
}

.modal-body{
    font-family: 'Cedora-Regular' !important;
    color: #6E132A;
    border-bottom: #6E132A 1px dotted;
    background-color: #fdfdfd;
}

.modal-body .form-control{
    font-family: 'Cedora-Regular' !important;
    color: #fdfdfd;
    background-color: rgba(110, 19, 42, .3);
    margin-bottom: 10px;
    font-weight: 700;
}

.modal-footer{
    background-color: #fdfdfd;
    align-self: center;
}

.modal-button-quit{
    border-radius: 50%;
    background-color: #6E132A;
    opacity: .2;
    color: #fdfdfd;
    border: #6E132A 2px solid;
    padding: .5rem;
    margin: .5rem;
    width: 60px;
    height: 60px;
}

.modal-button-quit:hover{
    background-color: #fdfdfd;
    color: #6E132A;
    border: #6E132A 2px solid;
}

.modal-button-save{
    border-radius: 5%;
    background-color: #6E132A;
    color: #fdfdfd;
    border: #6E132A 2px solid;
    margin: .5rem;
    width: 120px;
    height: 65px;
    opacity: .9;
}

.modal-button-save:hover{
    opacity: 1;
    border: none;
}

.header{
    width: 100% !important;
    padding: 10px;
}

.header .button-upload-image{
    margin: 0;
    align-self: center;
}

.header .image-preview{
    margin: 10px 0;
    aspect-ratio: 16/9;
}

.thumbnail{
    margin: 0;
    align-self: center;
}

.thumbnail .button-upload-image{
    margin: 0;
    align-self: center;
}

.thumbnail .image-preview{
    margin: 10px 0;
    aspect-ratio: 1;
}

.main{
    width: 100% !important;
    padding: 10px;
}

.main .button-upload-image{
    margin: 0;
    align-self: center;
}

.main .image-preview{
    margin: 10px 0;
    aspect-ratio: 1;
}

.edit{
    width: 100% !important;
    height: 270px;
    margin: 0 !important;
    padding: 10px;
    background-color: #6E132A;
    color: #fdfdfd;
    border-radius: 5px;
}

.edit p{
    font-size: .8rem;
}

.edit .button-upload-image {
    margin: 0 auto;
}

.button-upload-image:active{
    background-color: #6E132A;
    color: #fdfdfd;
    border: #fafafa 2px solid;
}

.edit .image-preview{
    aspect-ratio: 1;
    max-width: 150px !important;
}

.other-images-previews{
    aspect-ratio: 1;
    max-height: 300px;
}

.aux-button-modal{
    position: absolute;
    top: 15px;
    right: 15px;
    padding: 5px;
    font-size: 0.8rem;
    background-color: #fafafaad;
    color: #6e132a;
    border: none;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

.aux-button-modal:hover{
    background-color: #fafafa;
    color: #6e132a;
}

.label-no-display{
    position: absolute;
    top: 0;
    left: 0;
}

.form-control:focus {
    color: #6e132a;
    background-color: #6e132a13;
    border-color: #6E132A;
    outline: 0;
    box-shadow: 0 0 0 .25rem rgb(129, 38, 38);
}