body{
    overflow-x: hidden !important;
    font-family: 'Cedora-Regular', sans-serif;
}


h1, h2, h3, h4, h5, h6 {
    font-family: 'Cedora-Bold', sans-serif !important;
}

h2{
    color: #6E132A !important;
}
  
.btn-start-big {
    background-color: rgba(110, 19, 42, 0.9);
    color: white;
    border: none;
    box-shadow: 10px 5px 5px black;
    opacity: 90%;
    max-width: fit-content;
    width: fit-content;
    aspect-ratio: 5/2;
    font-size: 3rem !important;
    margin-left: 9rem;
    margin-bottom: 20px;
    padding: 10px;
}

.homepage {
    background: url(../../../public/img/HOMEPAGE/5_Capa-1.webp);
    background-size: cover;
}

.main-image {
    max-width: 100%;
    max-height: 850px;
    aspect-ratio: 16/9;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    box-sizing: content-box;
}

  
.init-text {
    font-size: 6rem;
    align-self: baseline;
    color: #fafafa;
    padding: 10px;
    margin-left: 20px;
}

.btn:hover{
    background-color: #6E132A;
    opacity: 100%;
}

.fix-height{
    aspect-ratio: 4/5;
    margin: 0 auto;
}

.rigth-section{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-between;
}

.upper-text {
    font-size: 2.5rem;
    text-align: right;
    color: #6E132A;
}

.lower-text {
    text-align: right;
    font-family: 'Cedora-Bold';
    color: #AD808A;
    font-size: 1.9rem;
    max-width: 75%;
}

.proyectos{
    min-height: 597px;
}

.section-title {
    font-size: 3.6rem;
    color: #6E132A;
    height: -moz-fit-content;
    height: fit-content;
    margin: 30px 15px;
}

.image-us{
    margin-left: 2rem ;
    margin-top: 3px ;
    margin-bottom: 3rem ;
}

.text-nosotros {
    font-size: 1.8rem;
    color: #6E132A;
    text-align: left;
    margin-top: 2.2%;
    line-height: normal;
}


.full-bottom-image{
    max-width: 100vw;
    margin: 0;
    padding: 0;
}

.bg-bottom {
    min-height: 900px ;
    background: url(../../../public/img/HOMEPAGE/IMAGEN-GRANDE.webp);
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: flex-start; 
    align-items: center;         
}

.text-main {
    font-size: 2.5rem;
    color: white;
    margin-top: 26px;
    margin-bottom: 10%;
    font-family: 'Cedora-Bold';
}

.text-main-lg {
    font-size: 3rem;
    color: white;
    font-family: 'Cedora-Bold';
    display: flex;
    flex-direction: column;
    margin-left: 18px;
}

.btn-start {
    display: flex;
    background-color: rgba(110, 19, 42, 0.9);
    opacity: 100%;
    color: white;
    border: none;
    font-size: 2.1rem!important;
    max-width: 400px;
    max-height: 130px;
    height: 100px;
    width: 300px;
    align-self: center;
    margin-top: 30px;
    margin-left: 0;
    justify-content: center;
    flex-direction: column;
    align-items: center;

}

.bottom-block {
    color: #6E132A;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin-bottom: 190px ;
}


.bottom-title {
    color: #6E132A;
    font-family: 'Cedora-Bold';
    font-size: 2.2rem;
    text-align: center;
    margin-top: revert;
}

.btn-bottom {
    display: flex;
    font-family: 'Cedora-Bold';
    background-color: rgba(110, 19, 42, 0.9);
    opacity: 95%;
    color: white;
    border: none;
    font-size: 2.1rem;
    width: 300px ;
    height: 81px ;
    align-self: center;
    margin-top: 30px ;
    margin-left: 0;
    align-items: center;
    justify-content: center;
}

.servicios .services{
    background: none;
}

@media (min-width: 1600px){
    .init-text{
        margin-left: 50px;
    }
}


@media (1201px >= width >= 1600px) {

    .bg-bottom{
        height: 900px;
    }
}

@media (max-width: 1200px) {


    .fw-bold-custom{
        margin-left: 1rem;
    }

    .btn-start-big{
        font-size: 2.5rem !important;
        margin: 30px 100px;
    }

    .fix-height{
        margin-top: 40px;
    }

    .upper-text {
        text-align: center !important;
        margin-bottom: 0px !important;
        font-size: 3rem;
    }

    .lower-text{
        text-align: center;
        font-size: 2rem;
        width: 100%;
        margin: 20px auto;
    }

    .section-title{
        font-size: 4.5rem;
        margin-bottom: 0;
        text-align: center;
    }

    .center-image{
        margin: 30px auto;
        margin-bottom: 0;
    }

    .text-nosotros{
        text-align: center;
    }

    .text-main{
        font-size: 1.5rem;
        margin: 0;
        margin-top: 20px;
    }

    .bg-bottom{
        max-height: 600px;
        min-height: 420px;
    }

    .text-main-lg{
        font-size: 1.5rem;
        width: 74%;
        margin-top: 80px;
    }

    .bottom-title{
        font-size: 1.5rem;
    }


    .btn-bottom{
        font-size: 1.6rem !important;
        height: 70px !important;
        width: 200px;
    }

}

@media only screen and (max-width: 767.98px) {

    .init-text{
        font-size: 4rem;
    }

    .btn-start-big {
        font-size: 1.7rem !important;
        margin: 0;
        margin-left: 100px;
        margin-bottom: 20px;
    }
    

    .upper-text{
        text-align: center !important;
        font-size: 2.45rem;
    }

    .lower-text{
        text-align: center ;
        font-size: 1.6rem ;
        max-width: 100%;
        margin: 20px auto;
    }

    .section-title{
        text-align: center !important;
    }

    .btn-start{
        font-size: 1.8rem !important;
        width: 222px;
        height: 70px;
        margin-top: 165px;
    }

    .text-nosotros{
        font-size: 1.4rem;
    }

}


@media (width <= 500px) {

    .init-text{
        font-size: 2.5rem;
        margin-left: 0;
        margin-bottom: 0;
    }

    .btn-start-big{
        font-size: 1.8rem !important;
        margin: 0px 30px;
        margin-bottom: 10px;
    }

    .upper-text{
        font-size: 2rem;
    }

    .text-main {
        font-size: 1.5rem;
    }

    .text-main-lg {
        font-size: 1.3rem;
        margin-left: 0;
    }

    .bg-bottom{
        object-fit: cover;
    }

    .bottom-title{
        font-size: 1.3rem;
    }

    .btn-bottom{
        font-size: 1.6rem !important;
        height: 60px !important;
        width: 180px;
    }

    .btn-start{
        margin-top: 130px;
    }
}
/*Vista Iphone 14 pro max*/
@media (width <= 430px) {

    .init-text{
        font-size: 3rem;
        margin-left: 0;
        margin-top: 70px;
    }

    .upper-text{
        font-size: 1.7rem;
    }

    .lower-text{
        font-size: 1.5rem;
    }

    .text-nosotros{
        font-size: 1.2rem;
    }

    body, html {
        overflow-x: hidden;
    }

}

@media (width <= 415px) {

    .lower-text{
        font-size: 1.3rem;
    }
}