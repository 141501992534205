.projects-section{
    font-family: 'Cedora-Bold' !important;
    padding: 16px;
}

.projects-section .title{
    font-size: 3rem;
    color: #6E132A;
    text-align: center;
    margin-top: 0;
    margin-bottom: 20px;
    left: 0;
    top: 0;
}

.button-container{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 1rem;
}

.button-add{
    width: max-content;
    font-size: 1.5rem;
    background-color: #6e132af2;
    opacity: .8;
    float: right;
    margin-right: 0;
    padding: 1rem;
    margin-top: 20px;
    border: none;
}

@keyframes toLarge{
    100%{
        width: 200px;
        transform: scale(1.1);
    }
}

.button-add:hover{
    opacity: 1;
    border: none;
    animation: toLarge 0.1s ease-out forwards; 
}

.modal-footer .button-add:hover{
    animation: none;
    background-color: #6E132A;
}

.projects-section .project-card{
    width: 100%;
    height: auto;
    background-color: #6E132A;
    opacity: .9;
    border-radius: 20px;
    margin: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 1.5rem;
    font-weight: 700;
    text-align: center;
    z-index: 1;
}

.projects-section .button-body{
    background-color: #fafafa;
    color: white;
    border: white 2px solid;
    border-radius: 50%;
    padding: 1rem;
    margin: 1rem;
    cursor: pointer;
    position: relative;
    width: auto;
    max-height: 55px;
    opacity: .8;
    top: 1%;
    margin-bottom: 2%;
}

.projects-section .button-icon{
    margin-top: -20%;
}

.button-body:hover{
   opacity: 1;
}

.projects-section .project-card{
    min-height: 500px;
    max-height: 630px;
}

.projects-section .project-card .project-name{
    font-size: 2rem;
    color: #fdfdfd;
    position: relative;
    padding: 15px;
}

.projects-section .project-card .card-image{
    max-width: 320px;
    border-radius: 2%;
    box-shadow: 0 0 10px 4px #9f3751;
}

.projects-section .card-body{
    align-self: center;
    padding: 20px;
    max-width: 95%;
}


.edit{
    position: relative;
}

body, html{
    overflow: auto;
}