.admin .container {
    background-color: #fdfdfd;
}

.admin .logo{
    max-width: 200px;
}

.logo-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px;
}

.admin .sidebar {
    position: fixed;
    top: 10%;
    left: 1%;
    width: 250px; /* Ajustar según tu preferencia */
    min-height: 80%;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    z-index: 1;
    row-gap: 0;
}

.category{
    font-size: 1.2rem;
    color: #fafafa;
    font-weight: 700;
    width: 200px;
    text-align: center;
    margin: 1px 0;
    padding: 1rem;
    border-radius: 10px;
    background-color: #6e132a;
    transition: all 0.3s;
    cursor: pointer;
}

.category:hover, .logout:hover {
    background-color: rgba(255, 255, 255, 0.1);
    border: #6e132a solid 2px;
}

.category, .logout {
    font-size: 1.2rem;
    color: #fafafa;
    font-weight: 700;
    text-align: center;
    padding: 1rem;
    border-radius: 10px;
    transition: all 0.3s;
    cursor: pointer;
}

  
.logout {
    font-size: 1.2rem;
    color: #fafafa;
    font-weight: 700;
    width: 200px;
    align-self: flex-start;
    text-align: center;
    margin: 10px 0;   
    padding: 1rem;
    border-radius: 10px;
    background-color: #8a2c3d;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    cursor: pointer;
}

.nav-pills .nav-link.active, .nav-pills .show>.nav-link {
    background-color: transparent;
    color: #6e132a;
}

.nav-pills .nav-link.active {
    background-color: #6e132a;
    color: #fafafa;
    transform: translateX(25px);
    box-shadow: -12px 0px 8px 0px rgba(0, 0, 0, 0.2);
}

@media screen and (max-width: 1441px) {
    .admin .sidebar {
        width: 215px;
    }

    .category{
        font-size: 1.15rem;
    }
}