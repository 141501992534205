.image-selection{
    padding: 16px;
    margin: 0 auto;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    gap: 16px;
    flex-wrap: wrap;
}

.image-selection .images{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    align-content: flex-start;
    gap: 13px;
    background-color: #6e132ad9;
    padding: 1rem;
    border-radius: 1%;
    min-height: 902px;
}

.image-item .left-images{
    width: 100%;
    border-radius: 5%;
}

.left-images img{
    border-radius: 5%;
}

.left-images .img-fluid{
    border-radius: 3%;
}

.image-selection .image-item{
    cursor: pointer;
    padding: 5px;
    margin: 0;
    box-sizing: content-box;
    align-self: flex-start;
}

.selected .left-images{
    border: 4px solid #e18aa0;
    width: fit-content;
    padding: 0;
    transform: scale(1);
}

.selected .layout-image{
    border: 2px solid #e18aa0;
    margin: 0 auto;
    padding: 0;
}


.image-selection .layouts{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    margin: 0;
    padding: 1rem;
    border: #6e132a dotted 2px;
    box-sizing: content-box;
}

.image-selection .layout-item{
    cursor: pointer;
    margin-bottom: 1rem;
    max-height: 200px;
    max-width: 400px;
    padding: .5rem;
    margin: 0 auto;
}

.image-selection .layout-item:hover{
    transform: scale(1.01);
}

.images-preview{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    padding: 1rem;
    margin: 1rem;
    border: #6e132a solid 2px;
    border-radius: 5px;
    max-width: 98%;
}

.saved-group{
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
    gap: 1px;
    padding: 1rem;
    width: 41%;
    background-color: #6e132a !important;
    overflow: hidden;
    border-radius: 2%;
    box-sizing: border-box;
}

.saved-group .tiny-image{
    max-width: 30%;
    height: auto;
    box-sizing: border-box;
}

.saved-group .tiny-image img{
    border-radius: 5%;
}

.select-button{
    padding: 10px;
    background-color: #6e132a;
    color: #fdfdfd;
    border: none;
    border-radius: 5%;
    cursor: pointer;
    width: max-content;
    height: 25px;
    box-sizing: content-box;
    margin-top: 5px;
    margin: 0 auto;
}

.select-button:hover{
    background-color: #FAFAFA;
    color: #6e132a;
    border: #6e132a solid 2px;
}

.select-button[disabled] {
    cursor: not-allowed;
    position: relative;
}

.select-button[disabled]:hover::after {
    content: attr(title);
    position: absolute;
    background: #333;
    color: #fff;
    padding: 5px;
    border-radius: 3px;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    white-space: nowrap;
    z-index: 1;
}

.return-button{
    padding: 10px;
    background-color: #6e132a;
    color: #fdfdfd;
    border: none;
    border-radius: 50%;
    cursor: pointer;
    width: 40px;
    height: 40px;
    box-sizing: content-box;
}

.tiny-image .delete-preview-image-button{
    top: -20%;
    right: -20%;
}

.modal-footer{
    justify-content: space-around;
    width: 50%;
}



.delete-group-button{
    padding: 10px;
    background-color: #fafafa;
    color: #6e132a;
    border: none;
    border-radius: 5%;
    cursor: pointer;
    width: max-content;
    height: auto;
    margin: 5px 0;
    box-sizing: content-box;
}

.delete-group-button:hover{
    background-color: #6e132a;
    color: #fdfdfd;
    border: #fafafa solid 2px;
}

.submit-button{
    padding: 10px;
    background-color: #6e132a;
    color: #fdfdfd;
    border: none;
    border-radius: 5%;
    cursor: pointer;
    width: 200px;
    height: 50px;
    box-sizing: content-box;
}

/* Eliminar flechas en navegadores webkit (Chrome, Safari) */
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.image-size-input{
    width: 80%;
    margin: 7px 0;
    text-align: center;
}