.main-image-contact{
    width: 100%;
    height: 150px;
    background: white;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.main-image-contact .navbar-light .navbar-nav .nav-link {
    color: #87767a;
}

.title-contact{
    font-size: 4rem;
    color: #6E132A;
    text-align: left;
    margin-left: 4rem;
    margin-bottom: 54px;
}

.form-spacing{
    margin-bottom: 2rem;
}

.form-section{
    display: flex;
    flex-direction: row;
    margin: 50px auto;
    justify-content: center;
}

.contact-section h2 {
    margin-bottom: 30px;
    color: #6E132A; /* Color de las letras modificado */
}
  
.contact-section form {
    color: #6E132A;
    background: #fff;
    border: none;
    font-size: 2rem;
}
  
.contact-section .form-control {
    border: 0;
    border-bottom: 2px solid #6E132A; /* Línea debajo del input */
    border-radius: 0; /* Bootstrap por defecto redondea los bordes, lo quitamos */
    background-color: transparent; /* Fondo transparente */
    color: #6E132A; /* Color de las letras modificado */
    padding-bottom: 0px;
}

.contact-section .form-control:focus {
    box-shadow: none; /* Quitamos el shadow que añade Bootstrap al enfocar */
}

.contact-section .form-floating-label {
    position: relative;
  }

.form-control {
    font-size: 1.2rem;
    border: none;
    border-bottom:5px ;
}
  
.contact-section .btn-primary {
    background-color: #d9534f; /* Ajusta este color según tu marca */
    border: none;
    padding: 10px 20px;
}

.contact-section .contact-info {
    margin-top: 20px;
}
  
.contact-section .contact-info p {
    color: #6E132A;
    margin-top: 11px;
}


.contact-section .form-floating-label label {
    position: absolute;
    top: 0;
    left: 0;
    pointer-events: none;
    transition: all 0.2s ease;
}
  
.contact-section .form-control:focus ~ label,
.contact-section .form-control:not(:placeholder-shown) ~ label {
    top: 100%;
    font-size: 65%;
    color: #6E132A;
}
  
.contact-section .button-submit {
    background-color: #6E132A;
    opacity: 0.9;
    border: none;
    display: flex;
    background-size: cover;
    color: white;
    font-size: 3.5rem;
    width: 296px;
    height: 91px;
    margin-left: 0px;
    margin-top: 20px;
    transition: transform 0.3s ease-in-out;
    align-items: center;
    justify-content: center;
}

.contact-section .button-submit:hover {
    transform: scale(1.1);
    opacity: 1;
}

.contact-section .button-submit:active {
    transform: scale(0.9);
    opacity: 0.8;
    background-color: #fafafa;
    color: #6E132A;

}

.contact-info{
    color: #6E132A;
    font-size: 2.4rem;
}

.text-section-bottom-contact{
    margin-top: 75px;
}

.modal-header .btn-close{
    position: absolute;
    top: 2%;
    right: 50%;
}

.btn-img-close{
    background-color: #6E132A;
    border: none;
    display: flex;
    background-size: cover;
    color: white;
    font-size: 3.5rem;
    width: 29rem;
    height: 91px;
    margin-top: 61px;
    margin-bottom: 107px;
    transition: transform 0.3s ease-in-out;
    align-items: center;
    justify-content: center;
}

.message{
    border: groove;
    text-align: center;
    font-family: 'Cedora-Regular';
    font-size: 1.5rem;
    color: #6E132A;
}

.title-modal{
    font-size: 2rem;
    width: 100%;
    color: #6E132A;
    font-family: 'Cedora-Bold';
    text-align: center;
    padding: 2rem;

}

.textarea.form-control{
    height: 10rem !important; /* Esto permite que el área de texto no tenga una altura fija */
    padding-bottom: 2px; /* Relleno pequeño para empezar el texto cerca del fondo */
    padding-top: 30px; /* Relleno superior para dejar espacio para la etiqueta "Mensaje" */
    overflow: hidden; /* Evita el desbordamiento del contenido */
}

.form-floating-label .form-control::placeholder {
    visibility: hidden;
}

/* Mostrar placeholder cuando el campo esté activo o tenga valor */
.form-floating-label .form-control:focus::placeholder,
.form-floating-label .form-control:not(:placeholder-shown) {
    visibility: visible;
}

.modal-dialog{
    position: relative;
    top: 40%;
}

#whatsapp-link {
    text-decoration: none; /* Elimina el subrayado por defecto */
    cursor: pointer; /* Cambia el cursor a un puntero */
}

#whatsapp-link:hover {
    text-decoration: underline; /* Añade un subrayado al pasar el mouse */
}

@media only screen and (max-width: 756.9px){

    .title-contact{
        text-align: center;
        margin-left: 0rem;
        margin-top: -70px;
    }
    
    .contact-section .form-floating-label {
        margin-bottom: 40px;
    }
    .contact-info{
        font-size: calc(1.5rem + 1.25vw);;
    }

    .bottom-contact .follow-us-text{
        margin-left: 0;
    }

    .main-image-contact .hamburger-btn {
        background-color: #6E132A;
    }
    .contact-section .form-floating-label label {
        font-weight: 100;
        font-size: smaller;
    }
}