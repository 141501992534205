.action-text{
    font-size: 2.06rem;
    line-height: 2.9rem;
    font-family: 'Cedora-Regular' !important;
    color: #87767a !important;
}

.master-plan-action-item{
    background: rgb(242,234,236);
    display: block;
    height: 120px;
    width: 33%;
    text-align: center;
    align-content: center;
}

@media screen and (max-width: 767.98px){
    .action-text{
        font-size: 1.1rem;
        line-height: 1.9rem;
    }

    .master-plan-action-item{
        height: 100px;
        width: 32%;
        margin-left: 0.35%;
        margin-top: 1rem;
    }
    
}