/* Container y layout general */
.card-content {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.rounded-container {
    background-color: #f2eaec;
    width: 95%;
    height: 650px;
    border-radius: 20px;
    position: relative;
    overflow-x: hidden;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 20px;
    margin-bottom: 20px;
}

/* Título de las tarjetas */
.name-card {
    font-size: 2rem;
    color: #6E132A !important;
    font-family: 'Cedora-Bold';
    text-align: center;
    margin: 20px auto;
}

/* Menú de opciones */
.menu-options {
    font-size: 1.4rem;
    line-height: 2rem;
    padding: 0px;
}

.header-option {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 95%;
    min-height: 30px;
    box-sizing: content-box;
    margin-bottom: 2px;
    padding: 5px;
}

.header-option-active {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 95%;
    min-height: 30px;
    box-sizing: content-box;
    margin-bottom: 2px;
    padding: 5px;
}

/* Estilos para cada opción */
.option {
    font-size: 1.3rem;
    color: #6E132A;
    margin-bottom: 0;
    text-align: start;
}

.option:hover {
    font-size: 1.3rem;
    color: #6E132A;
    margin-bottom: 0;
    text-align: start;
    text-decoration: underline;
}

.option-active {
    text-decoration: underline;
    color: #6E132A;
    font-size: 1.3rem;
    width: auto;
    margin-bottom: 0;
    text-align: start;
}


/* Listas expandibles */
.menu-option-item {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 5px 5px;
    transition: color 0.3s;
    border-radius: 4px;
    margin-bottom: 1px;
    text-align: center;
}

.menu-option-item-active {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 5px 5px;
    cursor: pointer;
    border-radius: 5px;
    margin-bottom: 2px;
}

.menu-option-item-active .expanded-content-container {
    display: block;
    width: 100%;
    padding-left: 20px;
    margin-left: 0;
}

.menu-option-item:hover {
    color: #6e132a;
    text-decoration: underline;
}

/* Íconos de las opciones */
.list-icon-menu-option {
    transform: rotate(270deg);
}

.list-icon-menu-option-active {
    transform: rotate(90deg);
}

/* Contenido expandido */
.expanded-content {
    list-style-type: none;
    padding-left: 20px;
    color: #87767a;
    text-decoration: none;
    font-size: 1.2rem;
    font-family: 'Cedora-Bold';
}

.expanded-content li {
    padding: 3px 0;
}

.list-icon {
    margin-top: -3px;
    margin-right: 5px;
}

/* Contenedor del video */
.video-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px auto;
}

/* Botones y secciones adicionales */
.plan-card-button {
    background-color: #6E132A;
    color: white;
    background-size: contain;
    align-self: center;
    opacity: 100%;
    border: none;
    width: 150px;
    height: 36px;
    position: relative;
    bottom: 10%;
}

.pdf-download-section {
    text-align: center;
    margin-bottom: 60px;
    margin-top: 140px;
}

.pdf-download-button {
    background-color: transparent;
    color: #87767a;
    padding: 10px 20px;
    border-radius: 5px;
    text-decoration: underline;
    font-size: 1.2rem;
}

.pdf-download-button:hover{
    background-color: transparent;
    color: #6E132A;
    text-decoration: underline;
}

.pdf-download-button:focus, .pdf-download-button:active {
    background-color: transparent;
    color: #87767a;
    text-decoration: underline;
}

.download-icon{
    margin-right: 5px;
}

/* Media Queries */

@media (width >= 1400px){
    .rounded-container {
        height: 714px;
        margin: 15px auto;
    }
}

@media (max-width: 1100px) {
    .rounded-container {
        height: 500px;
        margin: 15px auto;
    }

    .text-card {
        font-size: 1.4rem !important;
    }

    .name-card {
        top: 0%;
        font-size: 1.8rem;
    }
}

@media only screen and (max-width: 767.98px) {
    .option {
        font-size: 1.3rem;
        padding: 0;
        margin-left: -1px;
    }
    .rounded-container {
        margin-top: 16px;
        height: 500px;
    }
    .pdf-download-button {
        font-size: 1rem;
        padding: 15px 10px;
        box-sizing: border-box;
    }
}