.master-plan-container{
    width: 90vw;
}

.master-plan-caption{
    font-family: 'Cedora-Regular' !important;
    color: black !important;
    font-size: 1.62rem;
    margin-top: 19px;
    margin-left: 28px;
}

.master-plan-text{
    font-size: 1.95rem;
    color: #6E132A;
    text-align: justify;
    margin-bottom: 20px;
}

.master-plan-action-button{
    background-color: #6E132A;
    background-size: cover;
    background-position: center;
    width: 230px;
    height: 80px;
    border: none;
    margin-bottom: 10px;
    font-size: 1.5rem;
    opacity: .9;
}

.master-plan-action-button:hover{
    background-color: #6E132A;
    opacity: 1;
}

@media only screen and (max-width: 767.98px){
    .master-plan-container{
        width: 100vw;
    }

    .master-plan-caption{
        font-size: 1.2rem;
        margin-top: 10px;
        margin-left: 10px;
    }

    .master-plan-text{
        font-size: 1.25rem;
        margin-bottom: 2rem;
        margin-top: 1rem;
        text-align: center;
    }

    .master-plan-action-button{
        width: 150px;
        height: 50px;
        font-size: 1.2rem;
    }
    
}