.carousel-inner .carousel-item {
    transition: transform 0.1s ease; /* Ajusta la duración según prefieras */
}

.carousel{
    margin-left: 14px ;
    margin-right: 38px ;
}

.image-project{
    width: 395px ;
    max-height: 395px ;
    height: auto;
    cursor: pointer;
}

.service-carousel{
    margin-top: 17px ;
    margin-bottom: 17px ;
}

.landing-display{
    display: flex;
    justify-content: left;
    align-items: center;
}



@media (width >= 1200px) {
    .carousel-control-prev-icon{
        background-image: url(../../../public/img/HOMEPAGE/0_FLECHA-1_PROYECTOS.webp);
        width: 73px;
        height: 117px;
        position: absolute;
        left: 10px;
        top: 33%;
    }
    
    .carousel-control-next-icon{
        background-image: url(../../../public/img/HOMEPAGE/1_FLECHA-2_PROYECTOS.webp);
        width: 73px;
        height: 117px;
        position: absolute;
        right: 10px;
        top: 33%;
    }

    .services .carousel-control-prev-icon, .services .carousel-control-next-icon{
        display: none !important;
    }

    .proyectos .carousel-indicators{
        display: none !important;
    }

    .services .carousel-control-next, .services .carousel-control-prev{
        display: none;
    }

}

@media (width < 1200px) {
    
    .projects-carousel{
        display: flex;
        justify-content: center;
        height: 600px;
    }

    .services-carousel{
        display: flex;
        justify-content: center;
        height: 460px;
    }
    
    .carousel-indicators [data-bs-target]{
        background-color: #6E132A;
    }

    .carousel-indicators {
        bottom: -1%;
    }    

    .carousel-control-prev {
        top: -10%;
        opacity: 1;
    }

    .carousel-control-prev-icon{
        background-image: url(/public/img/UTILS/left-indicator.webp);
    }
    
    .carousel-control-next-icon{
        background-image: url(/public/img/UTILS/right-indicator.webp);
    }

    .carousel-control-prev-icon, .carousel-control-next-icon{
        position: relative;
        border: none;
        cursor: pointer;
        display: flex; /* Añadido para centrar la flecha */
        justify-content: center; /* Añadido para centrar la flecha */
        align-items: center; /* Añadido para centrar la flecha */
        width: 20px;
        height: 40px;
        background-color: transparent;
        background-size: contain;
        background-repeat: no-repeat;
        opacity: 1;
    }

    .carousel-control-prev-icon:hover, .carousel-control-prev-icon:focus, .carousel-control-next-icon:hover, .carousel-control-next-icon:focus {
        transform: scale(1.05); /* Hace que el botón crezca ligeramente al pasar el ratón por encima o al enfocar */
    }

    .carousel-control-prev-icon:active, .carousel-control-next-icon:active {
        transform: scale(0.95); /* Hace que el botón se contraiga ligeramente cuando se pulsa */
    }
    
    .left-button-bottom::before, .right-button-bottom::before {
        display: block;
        width: 0;
        height: 0;
    }

    .proyectos .carousel-control-next {
        top: -10%;
        opacity: 1;
    }
}

@media only screen and (max-width: 767.98px) {

    .servicios .carousel-indicators {
        display: flex !important;
        
    }    


    .servicios .carousel-control-next{
        top: -9%;
        opacity: 1;
    }
    
    .carousel{
        margin: 0;
    }

    .projects-carousel{
        height: 550px;
    }

}

@media only screen and (max-width: 580px) {
    .proyectos .carousel-control-next{
        top: -9%;
    }

    .servicios .carousel-control-next, .servicios .carousel-control-prev{
        top: 0%;
    }
}


@media only screen and (max-width: 470px) {

    .servicios .service-card{
        min-height: 400px;
    }

}

/*Vista Iphone 14 pro max*/
@media only screen and (max-width: 430px) {
}


@media only screen and (max-width: 400px) {

}