.main-text-init{
    font-size: 6rem;
    color: white;
    margin-left: 30px;
}

.right-text-description {
    margin-bottom: 0rem;
    width: 100% ;
    padding-inline: 10px;
}

.bottom-text-description {
    color: #6E132A;
    font-family: 'Cedora-Regular' !important;
    margin-bottom: 0px;
}

.principal-image{
    width: 100%;
    height: 100%;
    display: block;
    border-radius: 5px;
}

@media only screen and (max-width: 767.98px) {

    .main-text-init{
        font-size: 3rem;
        margin-left: 10px;
    }
    
    .left-text-description{
        line-height: 4rem;
        font-size: 1.9rem;
    }
    .right-text-description{
        margin-top: 17px;
        font-size: 1.5rem;
        width: 100% ;
    }
    .bottom-text-description{
        position: relative;
        top: 3%;
        text-align: left;
        width: 102%;
        left: 0%;
        font-size: 1.3rem;
        margin-bottom: 0px;
    }
}

@media only screen and (max-width: 430px){
}