.services-main-image {
    background: url('../../../public/img/SERVICIOS/IMAGEN-GRANDE.webp');
    aspect-ratio: 13 / 4 !important;
    background-size: cover;
} 

.services-content{
    width: 100vw;
    margin-bottom: 220px;
}

.section-title-services{
    font-size: 4.5rem;
    color: #6E132A;
    margin-left: 2rem;
    margin-bottom: 3rem;
}

.section-title-rest{
    font-size: 3.899rem;
    color: #6E132A;
    margin-left: 48px;
    margin-top: 174px;
    width: max-content;
}

.card-container{
    width: 100vw;
    margin-left: 1rem;
    margin-top: 43px;
    gap: 22px
}

  
.section-title-asesorias{
    text-align: right;
    font-size: 3.899rem;
    color: #6E132A;
    margin: 35px 20px
}


.container-asesorias{
    width: 100vw;
}


.service-card-container{
    width: 62vw;
    margin-left: 57px;
    margin-top: 8px;
    row-gap: 22px;
}

.service-card {
    display: flex;
    flex-direction: column;
    color: #6E132A;
    margin-bottom: 1.7rem;
}

.asesorias .image-service-card {
    height: 13.5rem;
}
  
.asesorias .card-title {
    font-size: 1.8rem;
    width: 31%;
    line-height: 3rem;
    position: absolute;
    top: 16%;
    left: .7%;
}

.asesorias .card-title-short {
    font-size: 1.8rem;
    width: 24%;
    line-height: 3rem;
    position: absolute;
    top: 25%;
    left: 3%;
}

.asesorias .card-text{
    position: absolute;
    font-size: 1.8rem;
    line-height: 2.6rem;
    width: 74%;
    top: 7%;
    left: 25.5%;
    color: #87767a;
    font-family: 'Cedora-Regular';
}


.instagram-post-long img {
    height: auto; /* O el valor específico que desees, como 300px, etc. */
    width: 231px;
}

.social-media-logos-container{
    cursor: pointer;
    
}

.follow-us{
    text-align-last: center;
    color: black !important;
    font-size: 1.52rem;
    font-family: 'Cedora-Regular' !important;
    margin-top: 32px;
    width: 104%;
}


.center-button{
    text-align: center;
}


.master-plan-actions {
    width: 100%;
}

.row-action-item{
    gap: 3px;
}


.master-plan-actions .master-plan-action-item.button {
    cursor: pointer;
}

.section-title-planes{
    text-align: left;
    font-size: 3.899rem;
    color: #6E132A;
    position: relative;
    left: 51%;
    margin-top: 10%;
}

.text-section-bottom{
    margin-bottom: 0vw;
    margin-top: 166px;
    margin-left: 23px;
}

.text-col{
    width: 100px;
    margin-left: 70px;
    margin-top: 37px;
    margin-right: 0px;
}

.planesComercio .upper-text{
    font-size: 4rem;
    margin: 50px auto;
    text-align: left !important;
}

.planesComercio .button-side{
    display: flex;
    justify-content: center;
    align-items: center;
}

.planesComercio .lower-text{
    text-align: left !important;
    font-size: 2.4rem;
    margin-top: 0rem;
}

.planes-container{
    text-align: right;
    display: block;
    align-content: center;
    margin-left: auto;
}

.follow-us-text{
    text-align: center;
    margin-top: 8px;
    margin-left: 10px;
    margin-bottom: -4px;
}

.social-media-logos-container-bottom{
    text-align: center;
    margin-top: 8%;
}

.images-phone{
    --bs-card-border-width: none;
}

.images-phone .card{
    border: none;
    border-radius: 5px;
}

.mute-button{
    opacity: 0.9;
}

.mute-button:hover .mute-button:focus{
    opacity: 1;
    border: none;
    background-color: #fafafa;
    color: #6E132A;

}

@media (width <= 1500px){
    .main-image-services{
        max-height: 500px;
    }
}

@media (width <= 1200px){
    .phone-display{
        margin: 30px auto;
    }
}


@media only screen and (max-width: 767.98px) {
    .main-image-services{
        height: 190px;
    }

    .services .section-title-services{
        font-size: 3rem;
        margin-left: 0rem !important;
        text-align: center;
    }    

    .services .btn-start-services{
        margin-left: 0px;
        margin-top: 36px;
        font-size: 2.5rem !important;
    }

    .asesorias .section-title-asesorias{
        text-align: center;
        margin-top: 125px;
        margin-bottom: 36px;
    }

    .asesorias .image-service-card {
        height: 100px;
    }
      

    .asesorias .card-title{
        font-size: .7rem !important;
        line-height: 1.5rem;
    }

    .asesorias .card-title-short{
        left: 4%;
        width: 23%;
        font-size: .7rem !important;
        top: 24%;
    }

    .asesorias .card-text {
        font-size: .8rem;
        line-height: 1.3rem;
        width: 73%;
        top: 4%;
        left: 25.5%;
    }

    .asesorias .card-text-short{
        font-size: .8rem;
        line-height: 1.3rem;
        width: 73%;
        top: 4%;
        left: 25.5%;
    }

    .asesorias .service-card-details {
        font-size: .84rem;
        width: 74%;
        left: 25.5%;
        top: 72%;
    }

    .asesorias .service-card-details span {
        margin-right: 0.5rem;
    }

    .follow-us{
        font-size: 1.4rem;
        margin-top: 1rem;
    }

    .planesComercio .section-title-planes{
        left: 0%;
        margin-top: 30%;
        font-size: 2.62rem;
    }

    .planesComercio .start-button-bottom {
        margin-bottom: 1rem;
    }

    .planesComercio .upper-text{
        text-align: center !important;
        font-size: 2.5rem;
        margin: 20px 0;
    }

    .planesComercio .lower-text{
        font-size: 1.7rem;
        margin-top: 17px;
        text-align: center !important;
    }

    .planesComercio .start-button-bottom{
        margin-top: 1rem;
    }

    .social-media-logos-container-bottom{
        max-height: 200px;
    }

}

@media only screen and (max-width: 430px) {

    .services .btn-start-services{
        margin-left: 0px;
        margin-top: 36px;
        font-size: 2.5rem !important;
    }

    .asesorias .section-title-asesorias{
        font-size: 3rem;
    }

    .asesorias .card-text {
        font-size: .7rem;
        line-height: 1.2rem;
        top: 6%;
    }

    .asesorias .card-text-short{
        font-size: .7rem;
        line-height: 1.2rem;
        width: 73%;
        top: 6%;
    }

    .asesorias .service-card-details {
        font-size: .74rem;
        width: 74%;
        left: 25.5%;
        top: 72%;
    }

    .asesorias .service-card-details span {
        margin-right: 0.5rem;
    }

    .planesComercio .lower-text{
        font-size: 1.5rem;
    }

    .planesComercio .phone-display{
        flex-direction: column;
    }


}