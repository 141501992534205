body {
    margin: 0;
    font-family: 'Cedora-Bold';
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow-x: hidden;
    overflow: scroll;
}

@font-face {
    font-family: 'Cedora-Bold';
    src: url('../public/fonts/cedora/Cedora-Bold.woff') format('truetype');
    font-weight: bold;
    font-style: normal;
}
  
  @font-face {
    font-family: 'Cedora-Regular';
    src: url('../public/fonts/cedora/Cedora-Regular.woff') format('truetype');
    font-weight: normal;
    font-style: normal;
}

h1, h2, h3, h4, h5, h6 {
    color: #6E132A;
}

.div div{
    overflow-x: auto;
}

.bg-light{
    --bs-bg-opacity: 0 !important;
}

.btn{
    font-family: 'Cedora-Bold' !important;
}

.logo{
    cursor: pointer;
    max-width: 220px;
}

.main-text-init {
    color: white;
}

.container-main {
    display: flex;
    overflow-x: hidden;
    flex-wrap: wrap;
    flex-direction: column;
    padding-inline: 41px;
    align-content: center;
}

.info-section {
    margin-bottom: 36px;
    width: max-content;
}

.images-section-project {
    width: max-content;
    margin-left: 28px;
    margin-bottom: 20px;
    column-gap: 15px;
}

.right-text-description {
    color: #6E132A;
    font-family: 'Cedora-Regular'!important;
    text-align: left;
    margin-bottom: 2.4rem;
    min-width: 60vw;
}

.bottom-text-description {
    color: #6E132A;
    font-family: 'Cedora-Regular'!important;
}


.block-bottom-projects {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.bottom-image-generativa {
    width: 100.94vw!important;
    min-height: 22.76vw;
    max-height: 1080px;
    background: url('../public/img/PROJECTS/Ampliación-generativa.webp') ;
    background-size: cover;
}

.text-section-bottom{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 3.28vw;
    margin-top: 40px;
}

.start-button-bottom{
    margin-top: 4rem;
    display: flex;
    opacity: 90%;
    background-color: #6E132A;
    color: white;
    border: none;
    font-size: 2.5rem !important;
    height: 112px;
    width: 320px;
    transition: transform 0.3s ease-in-out;
    align-items: center;
    justify-content: center;
}

.start-button-bottom:hover{
    background-color: #6E132A!important;
}

.start-button-bottom:focus, .start-button-bottom:active {
    background-color: #6E132A; 
    outline: none; 
    box-shadow: none; 
}

:focus-visible {
    outline: transparent auto 1px;
}

.btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled):active, .show>.btn-primary.dropdown-toggle {
    color: #fafafa;
    background-color: #6e132aaa;
    border-color: #6e132a;
}

.btn-primary.focus, .btn-primary:focus {
    color: #fafafa;
    background-color: #6e132aee;
    border-color: #6e132a;
    box-shadow: 0 0 0 .2rem #6e132ad1;
}

.btn:hover {
    color: #fafafa;
    background-color: #6e132aee;
    border-color: #6e132a;
}

.btn:focus-visible {
    color: #fafafa;
    background-color: #6e132aee;
    border-color: #6e132a;
    outline: 2px solid #6e132a !important;
    box-shadow: 0 0 0 .2rem #6e132ad1;
}

.nav{
    --bs-nav-link-color: #ad808a;
    --bs-nav-link-hover-color: #6E132A;
}

.img-fluid{
    border-radius: 5px;
}


@media (min-width: 960px) {
    .carousel-indicators{
        display: none !important;
    }
}


@media (max-width: 1100px) {

    .logo{
        display: none;
        position: absolute;
        z-index: 1050;
        width: 160px;
        left: 3%;
        top: 25%;
    }

    
    
    .btn-start-custom{
        display: flex;
        opacity: 100%;
        color: white;
        background-color: #6E132A;
        border: none;
        width: 250px;
        font-size: 2rem;
        height: 100px;
        align-self: center;
        margin-top: 1rem;
        margin-left: 0;
        justify-content: center;
        flex-direction: column;
        align-items: center;
    }

}


@media (min-width: 500px) and (max-width: 575px) {
    
    .hamburger-btn{
        top: 3%;
    }
    
}
@media (max-width: 475px) {
    
}