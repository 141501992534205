.navbar-title.nav-link:hover {
    color: #ad808a; /* Define el color al pasar el cursor */
}

.navbar-title{
    cursor: pointer;
    color: #6E132A; /* Color del texto */
    font-family: 'Cedora-Regular';
    font-size: smaller;
}

.service-list{
    color: #6E132A;
    width: max-content;
    line-height: 3rem;
}

.service-list span{
    font-size: 2.8rem;
}

.service-list img{
    vertical-align: text-bottom;
}

.button-col-center{
    display: flex;
    justify-content: center;
    align-items: center;
}
  
.btn-start-services {
    display: flex;
    background-color: #6E132A;
    opacity: 100%;
    color: white;
    border: none;
    font-size: 2.2rem !important;
    height: 100px;
    width: 312px;
    margin-left: 32px;
    justify-content: center;
    align-items: center;
}


.icon-left {
    margin-right: 20px;
    max-width: 1.4rem;
}


@media only screen and (max-width: 767.98px) {
    .services .navbar-title{
        font-size: 2rem;
        width: 61%;
    }
    .services .icon-left {
        margin-right: 10px;
        max-width: 1.2rem;
    }

    .service-list span {
        font-size: 1.8rem;
        vertical-align: top;
    }

    .services .section-title{
        font-size: 2.5rem;
        left: 0%;
    }

}

@media only screen and (max-width: 400px) {

    .services .navbar-title{
        font-size: 1.8rem;
        width: 80%;

    }

    .services .btn-start-services{
        font-size: 1.8rem !important;
        height: 80px;
        width: 250px;
        margin-left: 0px;
    }

    .services .section-title{
        font-size: 2.4rem;
        left: 0%;
    }

}