.dropzone-container-images{
    width: 75%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
}

.button-upload-image{
    background-color: #6E132A;
    color: white;
    border: white 2px solid;
    border-radius: 5%;
    padding: .5rem;
    margin: auto;
    position: relative;
    width: 150px;
    height: 50px;
    opacity: 1;
    transform: scale(1);
    animation: none;
    margin-bottom: 10px;
    margin-left: 0;
}

.button-upload-image:hover{
    border: #bf6a80 solid 2px;
}

.button-upload-image:not(:disabled):not(.disabled):active, .button-upload-image:focus{
    border: white 2px solid;
    border-radius: 5%;
}

.drag-and-drop{
    padding: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: rgba(110, 19, 42, .3);
    border-radius: 10px;
}

.drag-and-drop-images{
    background-color: #fafafa;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    row-gap: .5rem;
    min-height: 200px;
    border-radius: 10px;
    cursor: pointer;
    padding: 1rem;
    width: 75%;
    margin: 0 auto;
}

.drag-and-drop-images:disabled{
    cursor: not-allowed;
}

.delete-preview-image-button{
    position: absolute;
    top: -16%;
    right: -4%;
    width: 26px;
    height: 26px;
    background-color: #fafafa;
    border: 2px solid #6e312a;
    padding: .5rem;
    transform: scale(0.9);
    box-sizing: content-box;
}

.delete-preview-image-button:hover{
    background-color: #fafafa;
    border: #6E132A 2px solid;
    transform: scale(1);
}

.delete-preview-image-button:active{
    background-color: transparent;
    transform: scale(1);
}

.delete-preview-image-button:focus{
    background-color: transparent;
    transform: scale(1);
}

.delete-preview-image-button:not(:disabled):not(.disabled).active, 
.delete-preview-image-button:not(:disabled):not(.disabled):active, 
.show>.delete-preview-image-button.dropdown-toggle {
    color: #fff;
    background-color: transparent;
    border-color: transparent;
}

.delete-preview-image-button:not(:disabled):not(.disabled).active:focus, 
.delete-preview-image-button:not(:disabled):not(.disabled):active:focus, 
.show>.delete-preview-image-button.dropdown-toggle:focus {
    box-shadow: none
}

.create-project .button-add{
    background-color: #6E132A;
    color: white;
    border: white 2px solid;
    padding: .5rem;
    margin: auto;
    position: relative;
    width: max-content;
    height: auto;
    opacity: 1;
    transform: scale(1);
    animation: none;
}

.create-project .button-add:hover{
    transform: scale(1.01);
}

.create-project .button-add-below{
    background-color: #6E132A;
    color: white;
    border: none;
    border-radius: 30%;
    padding: .5rem;
    margin: .5rem;
    position: absolute;
    width: auto;
    max-height: 65px;
    opacity: 1;
    top: 70%;
    left: 34%;
    border-radius: 5%;
}

.create-project .button-add-below:hover{
    transform: scale(1.1);
    border: none;
}

.create-project .button-add-below:disabled{
    top: 55%;
    left: 34%;
    border-radius: 5%;
}

.create-project .button-add:hover{
    transform: scale(1.1);
    border: white 2px solid;
}

.create-project .button-add:disabled{
    top:55%;
    left: 34%;
    border-radius: 5%;
}

.create-project .title{
    font-size: 3rem;
    font-family: 'Cedora-Bold' !important;
    text-align: left;
    color: #6E132A;
    margin: 0;
    padding: 32px;
    left: 0;
}

.create-project .subtitle{
    font-size: 1.5rem;
    font-weight: 700;
    color: #6E132A9a;
    margin: 0;
    text-align: left;
    padding-inline: 40px;
}

.create-project .form-container{
    padding: 30px;
    margin: 30px 0;
    border-radius: 20px;
    background-color: #6E132A;
    
}

.create-project .title-form{
    width: 80%;
    font-size: 2.5rem;
    color: #fafafa !important;
    margin: 16px auto;
    text-align: left;
    padding: 10px auto;
}

.create-project .form-label{
    font-size: 1.5rem;
    margin-bottom: 8px;
    text-align: left;
    color: #fafafa;
    padding-inline: 11.5%;
}

.create-project .form-control{
    width: 75%;
    font-size: 1.2rem;
    padding: 8px;
    margin: 0 auto;
    margin-bottom: 16px;
    border-radius: 10px;
    border: none;
    background-color: #fafafa;
}

.create-project .form-control[readonly]{
    color: #fafafa;
    background-color: #bf6a80;
}

@keyframes ripple {
    0% {
      transform: scale(0);
      background-color: #6E132A;
      opacity: 1;
    }
    100% {
      transform: scale(4);
      background-color: #6E132A;
      opacity: 0;
    }
}

.create-project .button-save {
    position: relative;
    overflow: hidden; /* Asegúrate de que el pseudo-elemento no salga del botón */
    background-color: #fafafa;
    border: #fafafa 2px solid;
    color: #6E132A;
    padding: .5rem;
    margin-top: 30px;
    transition: background-color 0.3s ease, color 0.3s ease;
}
  
.create-project .button-save::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.5); /* Color inicial con opacidad */
    border-radius: 50%;
    transform: translate(-50%, -50%) scale(0);
    transition: none; /* No transición aquí */
    pointer-events: none; /* Evitar que el pseudo-elemento interfiera con los eventos del mouse */
  }
  
.create-project .button-save:hover::before {
    animation: ripple 0.3s ease-in-out;
}

.create-project .button-save:hover{
    animation: pulse 0.3s ease 0.1s forwards;
}

.create-project .button-save:disabled{
    background-color: #fafafa;
    color: #6E132A;
    border: #fafafa 2px solid;
}

.create-project .text-muted{
    color: #fafafa !important;
    margin-left: 105px;
    margin-bottom: 10px;

}

.btn-primary:not(:disabled):not(.disabled):active:focus {
    box-shadow: 0 0 0 .2rem #fafafa;
}

@keyframes pulse {
    90% {
        background-color: #6E132A;
    }
    100%{
        color:#fafafa;
        background-color: #6E132A;
    }
}

.create-project .button-return{
    background-color: transparent;
    border: none;
    width: 85px;
    margin-top: 30px;
    transition: transform .3s ease;
}

.create-project .button-return:hover{
    animation: rotate-icon .5s ease-in-out ;
}

@keyframes rotate-icon {
    0% {
        transform: rotate(0deg);
    }
    25%{
        transform: rotate(-15deg);
    }
    50%{
        transform: rotate(15deg);
    }
    100% {
        transform: rotate(0deg);
    }   
}

@media screen and (max-width: 1440px) {
    .create-project .title{
        padding: 0px 50px;
        margin-top: 50px;
    }

    .create-project .subtitle{
        padding: 20px 60px;
    }

    .create-project .form-container {
        margin: 0 auto;
    }
}