.project-card{
    width: max-content;
    height: auto;
}

.card{
    border: none;
}

.cards-container{
    justify-content: center;
    margin-right: 43px ;
    margin-left: 59px ;
}
.card-img{
    max-height: 395px ;
    height: auto;
    cursor: pointer;
}

.projects-carousel .project-card{
    display: flex;
    flex-direction: column;
    color: #6E132A;
    margin-bottom: 1.7rem;
    justify-content: center;
    aspect-ratio: 5/6;
    width: fit-content;
}

.projects .card-img{
    aspect-ratio: 1/1;
}

.servicios .service-card{
    display: flex;
    flex-direction: column;
    color: #6E132A;
    margin-bottom: 1.7rem;
    min-height: 450px;
    justify-content: center;
    position: relative;
    width: fit-content;
}

.servicios .card-img{
    height: auto;
    cursor: pointer;
    max-width: 100%;
}

.card-body {
    padding: 0;
    margin-top: 5px ;
    color: #6E132A;
    font-family: 'Cedora-Bold';
    align-self: flex-start;

}

.card-title {
    color: #6E132A;
    font-family: 'Cedora-Bold';
    align-self: flex-start;
    position: absolute;
    bottom: 0%;
}

@media (width >= 1201px) {

    .servicios .card-img {
        height: auto;
        cursor: pointer;
        max-width: 100%;
    }
}

@media (width <= 1200px) {

    .servicios .card-img {
        height: auto;
        cursor: pointer;
        max-width: 100%;
    }

    .servicios .card-title{
        font-size: 1.1rem;
        bottom: 0%;
    }

    .projects-carousel .project-card{
        width: 100%;
        height: 550px;
    }

    .card-img{
        max-height: 380px;
        width: 100%;
    }

}

@media only screen and (max-width: 767.98px) {
    .projects-carousel .card-img{
        width: 100%;
        align-self: center;
        max-height: 480px;
    }

    .card-title{
        font-size: 1.3rem;
    }

    .servicios .card-img{
        align-self: center;
        max-height: 480px;
    }

    .projects-carousel .project-card {
        height: 500px;
    }

}

@media only screen and (max-width: 580px) {
    .service-card{
        align-items: center;
        justify-content: center;
    }

}

@media only screen and (max-width: 500px) {
    .card-title {
        font-size: 1rem !important;
    }
}

@media only screen and (max-width: 470px) {
    .card-title {
        font-size: .7rem;
    }
}

/*Vista Iphone 14 pro max*/
@media only screen and (max-width: 430px) {

}

