.user-card{
    width: 91%;
    height: auto;
    background-color: #6E132A;
    opacity: .9;
    border-radius: 20px;
    margin: 1rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 1.5rem;
    font-weight: 700;
    text-align: left;
    z-index: 1;
}

.user-card-title{
    color: #fdfdfd;
    position: relative;
    top: 0;
    font-size: 2rem;
    border-bottom: #fdfdfd 1px solid;
}

.user-card-text{
    color: #fdfdfd;
    position: relative;
    top: 0;
    left: 10%;
    font-size: 1.5rem;
}

.user-card .card-body{
    padding: 1rem;
}

.user-card .authorization-button{
    background-color: transparent;
    color: white;
    border: white 2px solid;
    border-radius: 5%;
    padding: 1rem;
    margin: 1rem;
    position: relative;
    width: auto;
    max-height: 55px;
    opacity: .8;
    left: -11%;
}

.user-card .modal-button-quit{
    position: relative;
    border: #fdfdfd 2px solid;
    left: -9%;
}

.user-card-footer{
    border: none;
    margin-top: 2.5rem;
}

.btn.disabled, .btn:disabled, fieldset:disabled .btn {
    background-color: #cccccc;
    color: #666666;
    border: none;
}