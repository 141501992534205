.card-asesoria {
    display: flex;
    flex-direction: row;
    border-radius: 20px;
    min-height: 216px;
    max-width: 100%;
    margin-bottom: 1.7rem;
    transition: transform 0.3s ease; /* Esto asegura una transición suave */
    transform: translateY(0)
}

.card-asesoria:hover {
    transform: translateY(-10px) scale(1.01);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  }

.card-asesoria-title-section {
    background-color: rgb(235,223,226);
    color: rgb(110,19,42);
    display: block;
    align-content: center;
    max-width: 210px;
    border-radius: 20px 0px 0px 20px;
}

.card-asesoria .title-card{
    font-size: 1.75rem;
    font-weight: 700;
    margin-left: 0%;
    max-width: 220px;
    text-align: center;
}


.card-asesoria-description-section {
    background-color: rgb(242,234,236);
    color: white;
    display: block;
    align-content: center;
    border-radius: 0px 20px 20px 0px;
}

.card-asesoria .description-card{
    font-size: 1.6rem;
    font-family: 'Cedora-Regular' !important;
    color: #87767a !important;
    margin-left: 0%;
    margin-top: -6%;
}

.card-asesoria-details {
    position: absolute;
    bottom: 0;
    background-color: rgb(224,213,216);
    min-height: 50px;
    font-size: 1.7rem;
    border-radius: 0px 0px 20px 0px;
    text-align: center;
}
  

.card-asesoria span {
    display: inline-block;
    width: max-content;
    margin-right: 4%;
    font-size: 1.4rem;
    margin-top: 1.5%;
    margin-right: 5%;
}

@media (width <= 1400px){

    .card-asesoria .col-9{
        width: 65%;
    }

}

@media screen and (max-width: 767.98px){

    .card-asesoria{
        min-height: 160px;
    }

    .card-asesoria .title-card{
        font-size: 1.2rem;
        margin-left: 0%;
        text-align: center; /*ver si gusta*/
    }

    .card-asesoria .description-card{
        font-size: 1rem;
        margin-top: -16%;
    }

    .card-asesoria span {
        display: inline-block;
        width: max-content;
        font-size: 0.66rem;
        margin-top: 0%;
        margin-right: 3%;
    }

    .card-asesoria .col-9{
        width: 65%;
    }

    .card-asesoria-details{
        display: flex;
        align-items: center;
        min-height: 30px;
        height: 25px;
    }
    
}

@media screen and (max-width: 400px){

    .card-asesoria{
        min-height: 140px;
    }

    .card-asesoria .title-card{
        font-size: .83rem;
        margin-left: 0%;
        text-align: center; /*ver si gusta*/
    }

    .card-asesoria .description-card{
        font-size: .83rem;
        margin-top: -12%;
    }

    .card-asesoria span {
        display: inline-block;
        width: max-content;
        font-size: 0.58rem;
        margin-top: 1%;
        margin-right: 3%;
    }

    .card-asesoria .col-9{
        width: 65%;
    }
    
}
  